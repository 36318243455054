@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inconsolata", monospace;
}

input {
  font-family: "Inconsolata", monospace !important;
}
body {
  height: 100vh;
  color: #808080;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-optical-sizing: auto;
}
.body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
