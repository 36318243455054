.result-body {
  padding: 25px;
  width: 450px;
  height: 200px;
  border: 2px dashed wheat;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.generated {
  margin-top: 15px;
}

@media only screen and (max-width: 768px) {
  .result-body {
    width: 350px;
    height: 100px;
  }
  .generated svg {
    display: none;
  }
  h1 {
    font-size: 30px;
  }
}
